import React from 'react';
import ReactDOM from 'react-dom/client';

import { CartProvider } from './src/context/CartContext';
import { withTrans } from './src/i18n/withTrans';
import { withApolloProvider } from './src/gatsby-plugin-apollo/provider';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
require('./src/styles/global.scss');
import i18n from './src/i18n/config';
import { I18nextProvider } from 'react-i18next';

// cart provider (for context)
export const wrapRootElement = ({ element, pathname }) => {

  const locale = ['fr', 'de'].includes(pathname?.slice(1, 3)) ? pathname.slice(1, 3) : 'fr'

  if (pathname?.length && locale !== i18n.language && i18n?.options?.languages.includes(locale)) {
    i18n.changeLanguage(locale)
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdhhfwpAAAAAHUILOyV5BD7LqxmGtF1axCr-9lD" useEnterprise={true} container={{
      // element: "test",
      parameters: {
        theme: 'dark'
      }
    }}>
      <I18nextProvider i18n={i18n}>
        {element}
      </I18nextProvider>
    </GoogleReCaptchaProvider>
  );
};

// i18n provider (for translations)
// export const wrapPageElement = ({ element }) => {
//   withTrans(element);
// };

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export function shouldUpdateScroll(prevRouterProps, { location }) {
  if (location && location.hash && location.hash.length === '0') {
    window.scrollTo(0, 0)
  }
  window.scrollTo(0, 0)

  const body = document.getElementsByTagName('body')[0]
  body.scrollTop = 0
  return false
}
